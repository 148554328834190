// FOR DEVELOPMENT PURPOSES ONLY: Copy and paste your own Firebase config here to view user data in the Firebase console online

const firebaseConfig = {
  apiKey: "AIzaSyAJ98ovLt3NU9qTU2NdXLWf3_zmJc5kftI",
  authDomain: "peels-41c1d.firebaseapp.com",
  projectId: "peels-41c1d",
  storageBucket: "peels-41c1d.appspot.com",
  messagingSenderId: "976382827038",
  appId: "1:976382827038:web:a0719597c31ac802262c0f",
  measurementId: "G-H7YPC2EY7S"
};

export default firebaseConfig;